import * as React from 'react'
import {useRouter} from 'next/router'
import {UserAuthStatusContext} from 'wrapper/UserAuthStatusContext'

import {ToggleContext, useAnalyticsQueue} from '@invitae/nucleobase'
import {CartContext} from '@invitae/physician-cart'
import {OrderContext} from '@invitae/stargate'

import {QUERY_NAME} from 'components/CategoryTabSection/CategorySideMenu'
import {redirectToPGxPage, redirectToSignIn} from 'components/PanelCardsSection/PanelCardsItem/utils'
import {
  getSelectedPanelGenesFromTotalUnselectedGeneList,
  onlyOneGeneSelectedRemained,
} from 'components/PanelDetail/panelDetailUtils'
import {CONFIRM_ADD_TO_CART_ANALYTICS_EVENT} from 'constants/analytics'
import {Authenticated, Unauthenticated} from 'constants/authState'
import {
  disabledGeneCountPanelList,
  NIPS_SINGLETON_MICRODELETION_SYNDROMES_ADDON,
  PANEL_CUSTOMISATION_STATUS_ITEM,
  PGX_PANEL_CODE,
  PRODUCT_TYPES_MIX,
  shouldShowNameProductTypeList,
} from 'constants/specialCases'
import {IOptionalTestData, IPanelData, IPanelGene} from 'hooks/types'
import {getCleanCode} from 'utils/utils'

export enum PanelType {
  HUB = 'HUB',
  DETAIL = 'DETAIL',
  CATEGORY = 'CATEGORY',
}

interface IHandlePanelCardsProps {
  primaryPanelData: IPanelData
  optionalTestsData?: IOptionalTestData[]
  type: PanelType
}

export interface IHandlePanelCardsResult {
  cleanPanelCode: string
  handleAddButtonClick: (onAddToCartSuccess?: () => void) => Promise<void>
  //handle select and add to cart
  handleSelectedPanel: (isSelected: boolean, panelId: string) => void
  isAddonProvided?: boolean
  //state utils
  isGeneCountEnabled: (panelName: string, isSelected?: boolean) => boolean
  onGeneSelect: (geneItem: IPanelGene, panelCode: string, isPrimary?: boolean, isRequired?: boolean) => void
  optionalTestsData?: IOptionalTestData[]
  primaryPanelData: IPanelData
  resetSelectedItems: () => void
  sectionHeading: string | string[]
  //main data
  selectedPanels: string[]
  setIsHoverOverTags: React.Dispatch<React.SetStateAction<boolean>>
  showAdded: boolean
  totalGenesCount: number
  unselectedGenes: IPanelGene[]
  isNIPSMicrodeletionSyndromesAddon: (panelName: string) => boolean
  showNameOnOrderTest: (productType: string) => boolean
}

/**
 * hook functionality:
 * - provides selected panels and support it's updates
 * - provides gene count
 * - handles adding panels to cart
 */

function useHandlePanelCards({
  primaryPanelData,
  optionalTestsData,
  type,
}: IHandlePanelCardsProps): IHandlePanelCardsResult {
  const {cxPgxHcxOnlineOrdering} = React.useContext(ToggleContext)

  const {authState} = React.useContext(UserAuthStatusContext)
  const {addToCart} = React.useContext(CartContext)
  const {logEvent} = useAnalyticsQueue()
  const router = useRouter()

  //main provided data from props
  const panelCode = primaryPanelData.panelCode!
  const cleanPanelCode = getCleanCode(panelCode)
  const sectionHeading = React.useMemo(() => router.query[QUERY_NAME] || 'All tests', [router.query])

  /**add optional test items marked as required to the selected items*/
  //used only for the next defaultCodeList value
  const defaultSelectedOptionalTests = React.useMemo(
    //[test.panelCode !== panelCode] <- this check handles carrier comprehensive screening where one of the addon is actually split up primary panel item
    () =>
      optionalTestsData
        ?.filter(test => test.isDefaultChecked || (test.isRequired && test.panelCode !== panelCode))
        .map(item => item.panelCode!),
    [optionalTestsData],
  )

  const defaultCodeList: string[] = [primaryPanelData.panelCode ?? ''].concat(
    defaultSelectedOptionalTests ? defaultSelectedOptionalTests : [],
  )

  const showNameOnOrderTest = React.useCallback(
    (productType: string) => {
      const shouldShowNameProduct = shouldShowNameProductTypeList.includes(productType)

      return shouldShowNameProduct || primaryPanelData?.geneList?.length === 0
    },
    [primaryPanelData],
  )

  const isNIPSMicrodeletionSyndromesAddon = (panelName: string) => {
    return panelName.includes(NIPS_SINGLETON_MICRODELETION_SYNDROMES_ADDON)
  }

  const uniqueOptTests = React.useMemo(
    () => optionalTestsData?.filter(test => test.isUnique).map(item => item.panelCode),
    [optionalTestsData],
  )

  const totalGenesCount = React.useMemo(() => {
    const optionalTestListLength =
      optionalTestsData?.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue.totalGenesCount ?? 0)
      }, 0) ?? 0
    const totalGeneCount = (primaryPanelData.totalGenesCount ?? 0) + optionalTestListLength
    return totalGeneCount
  }, [optionalTestsData, primaryPanelData])

  const isAddonProvided = optionalTestsData && optionalTestsData.length > 0

  const [showAdded, setShowAdded] = React.useState(false)

  // should be used for main category page only now
  const [isHoverOverTags, setIsHoverOverTags] = React.useState(false)
  /** selected panel code that will be added to cart */
  const [selectedPanels, setSelectedPanels] = React.useState<string[]>(defaultCodeList)

  // is used for Panel Detail page only
  const [unselectedGenes, setUnselectedGenes] = React.useState<IPanelGene[]>([])

  const isGeneCountEnabled = React.useCallback(
    () => !disabledGeneCountPanelList.includes(cleanPanelCode) && totalGenesCount > 0,
    [cleanPanelCode],
  )

  /**
   * Used for logEvent analytics properties
   */
  const isPanelCustomized = React.useCallback(() => {
    if (!optionalTestsData) return 'N/A'
    if (selectedPanels.join('') === panelCode) return 'No'
    return 'Yes'
  }, [optionalTestsData, panelCode, selectedPanels])

  const getPropsForAnalytics = React.useCallback(() => {
    switch (type) {
      case 'CATEGORY':
        return {
          customized: isPanelCustomized(),
          // should be used for main category page only now
          'Ordering tag hover': isHoverOverTags ? 'Yes' : 'No',
          section: sectionHeading,
        }
      case 'DETAIL':
        return {
          'add-on genes': isPanelCustomized(),
          'customized panels':
            unselectedGenes.filter((item: IPanelGene) =>
              primaryPanelData?.geneList?.some(gene => gene.code === item.code),
            ).length > 0
              ? 'Yes'
              : 'No',
        }
      default:
        return {
          customized: isPanelCustomized(),
          section: sectionHeading,
        }
    }
  }, [type, sectionHeading, isHoverOverTags, unselectedGenes, primaryPanelData.geneList])

  /**
   * HANDLE ADDING SELECTED ITEMS TO CART
   */

  //function os a part of handleAddButtonClick
  const addSelectedItemsToCart = React.useCallback(
    async (panelCode: string, selectedOptions: string[], orderContext: OrderContext[]) => {
      logEvent({
        eventName: CONFIRM_ADD_TO_CART_ANALYTICS_EVENT,
        eventProperties: {
          'Panel Name': primaryPanelData.title,
          ...getPropsForAnalytics(),
        },
      })

      if (authState === Authenticated) {
        const cartResponse = await addToCart(selectedOptions, undefined, orderContext)
        /* TODO: Handle cart validation issue: https://invitae.jira.com/browse/CFTC-147 */
        if (cartResponse?.errors && cartResponse.errors['validationCode'] !== PRODUCT_TYPES_MIX) {
          return false
        }
        return true
      }
      sessionStorage.setItem(
        PANEL_CUSTOMISATION_STATUS_ITEM,
        JSON.stringify({
          panelCode: panelCode,
          selectedPanels: selectedPanels,
          unselectedGenes: unselectedGenes,
        }),
      )
      if (authState === Unauthenticated) redirectToSignIn()
      return false
    },
    [addToCart, authState, isPanelCustomized, logEvent, getPropsForAnalytics],
  )

  const getTotalPanelGeneList = React.useCallback(
    (selectedPanels: string[]): IPanelGene[] => {
      if (!optionalTestsData || optionalTestsData.length <= 0) {
        return primaryPanelData.geneList ?? []
      }
      const optionalTestsDataGenes: IPanelGene[] = []
      optionalTestsData.map(item => {
        if (selectedPanels.includes(item.panelCode!)) {
          optionalTestsDataGenes.push(...(item.geneList ?? []))
        }
      })
      return primaryPanelData.geneList!.concat(optionalTestsDataGenes)
    },
    [optionalTestsData, primaryPanelData],
  )

  const getOrderContextFromSelectedPanels = React.useCallback(
    (selectedPanels: string[], unselectedGenes: IPanelGene[]): OrderContext[] => {
      if (unselectedGenes.length === 0) {
        return [{codes: selectedPanels, parentId: '', parentType: ''}]
      }

      const orderContext: OrderContext[] = []
      orderContext.push({
        codes:
          primaryPanelData.geneList
            ?.filter(gene => !unselectedGenes.some(unselectedGene => unselectedGene.name === gene.name))
            .map(gene => gene.code) ?? [],
        parentId: primaryPanelData.panelCode,
        parentType: 'panel',
      })

      if (!optionalTestsData || optionalTestsData.length === 0) {
        return orderContext
      }

      optionalTestsData.map(item => {
        if (selectedPanels.includes(item.panelCode!)) {
          orderContext.push({
            codes:
              item.geneList
                ?.filter(gene => !unselectedGenes.some(unselectedGene => unselectedGene.name === gene.name))
                .map(gene => gene.code) ?? [],
            parentId: item.panelCode,
            parentType: 'add-on',
          })
        }
      })

      return orderContext
    },
    [optionalTestsData, primaryPanelData],
  )

  /** when user clicks on simple add with only main panel */
  const handleAddButtonClick = React.useCallback(
    async (onAddToCartSuccess?: () => void) => {
      //handle PGx special case, redirect to separate order page

      if (cleanPanelCode === PGX_PANEL_CODE && !cxPgxHcxOnlineOrdering) {
        redirectToPGxPage()
      } else {
        const selectedGenes = getSelectedPanelGenesFromTotalUnselectedGeneList(
          unselectedGenes,
          getTotalPanelGeneList(selectedPanels),
        )
        //map the gene items to codes
        const selectedItems = unselectedGenes.length > 0 ? selectedGenes.map(item => item.code) : selectedPanels
        const orderContext = getOrderContextFromSelectedPanels(selectedPanels, unselectedGenes)
        const isSuccessfulAddToCart = await addSelectedItemsToCart(cleanPanelCode, selectedItems, orderContext)

        //reset function if the adding to cart is unsuccessful of has been interrupted
        if (!isSuccessfulAddToCart) return
        setShowAdded(true)
        onAddToCartSuccess && onAddToCartSuccess()
      }
    },
    [
      cleanPanelCode,
      cxPgxHcxOnlineOrdering,
      unselectedGenes,
      getTotalPanelGeneList,
      selectedPanels,
      addSelectedItemsToCart,
    ],
  )

  React.useEffect(() => {
    const panelCustomizationStatus = sessionStorage.getItem(PANEL_CUSTOMISATION_STATUS_ITEM)
    if (panelCustomizationStatus && authState.isAuthenticated) {
      const {panelCode, selectedPanels, unselectedGenes} = JSON.parse(panelCustomizationStatus)
      if (panelCode === getCleanCode(primaryPanelData.panelCode!)) {
        setSelectedPanels(selectedPanels)
        setUnselectedGenes(unselectedGenes)
      }
      sessionStorage.removeItem(PANEL_CUSTOMISATION_STATUS_ITEM)
    }
  }, [])

  /**
   * HANDLE SELECTING ADD-ON ITEMS
   */

  //function is a part of handleSelectedPanel
  const handleUniqueItems = React.useCallback(
    (prev: string[], panelId: string) => {
      let listWithRemovedPanels = prev
      const foundExistingUniqueItems = prev.find(item => uniqueOptTests?.includes(item))
      //don't trigger filtering is there's only one unique item
      if (uniqueOptTests?.includes(panelId) && foundExistingUniqueItems && foundExistingUniqueItems?.length > 0) {
        uniqueOptTests.map(uniqueId => {
          //skip the selected unique item
          if (uniqueId !== panelId) {
            //remove all unique items from selected list
            listWithRemovedPanels = listWithRemovedPanels.filter(item => item !== uniqueId)
          }
        })
        return [...listWithRemovedPanels, panelId]
      }
    },
    [uniqueOptTests],
  )

  // remove from unselected array all genes when add-on is selected
  const resetUnselectedItemForPanel = React.useCallback(
    (panelId: string) => {
      const genesTest = optionalTestsData?.find(item => item.panelCode === panelId)?.geneList ?? []
      const genes = [...unselectedGenes].filter(
        unselectedGenesItem => !genesTest.some(genesTestItem => genesTestItem.name === unselectedGenesItem.name),
      )
      setUnselectedGenes(genes)
    },
    [unselectedGenes, optionalTestsData],
  )

  const handleSelectedPanel = React.useCallback(
    (isSelected: boolean, panelId: string) => {
      if (panelId === '') return
      setSelectedPanels(prev => {
        if (isSelected) {
          if (prev.includes(panelId)) {
            return prev
          } else {
            //handle unique items
            const handledUniqueItems = handleUniqueItems(prev, panelId)
            if (handledUniqueItems) return handledUniqueItems
            return [...prev, panelId]
          }
        } else {
          if (panelId === primaryPanelData.panelCode && prev.length > 1) {
            return prev
          } else {
            const listWithRemovedPanel = prev.filter(item => item !== panelId)
            return listWithRemovedPanel
          }
        }
      })

      //handle cases for unselectedGenes
      //user clicks on customised addon, addon should close and reset genes
      if (!isSelected && unselectedGenes.length > 0) {
        resetUnselectedItemForPanel(panelId)
      }
    },
    [setSelectedPanels, primaryPanelData, handleUniqueItems, unselectedGenes, resetUnselectedItemForPanel],
  )

  const resetSelectedItems = React.useCallback(() => {
    setSelectedPanels(defaultCodeList)
  }, [setSelectedPanels, defaultCodeList])

  const unselectPanelWhenAllGenesUnselected = React.useCallback(
    (genes: IPanelGene[], panelCode: string) => {
      if (!optionalTestsData || optionalTestsData.length <= 0) return
      const genesList = optionalTestsData.filter(item => item.panelCode === panelCode)[0].geneList
      if (!genesList) return
      const selectedGenes = genesList.filter(
        genesListItem => !genes.some(geneItem => geneItem.name === genesListItem.name),
      )
      if (selectedGenes && selectedGenes.length === 0) {
        handleSelectedPanel(false, panelCode)
        resetUnselectedItemForPanel(panelCode)
      }
    },
    [optionalTestsData, handleSelectedPanel, resetUnselectedItemForPanel],
  )

  const getCorrectSourceGeneList = (panelCode: string, isPrimary?: boolean): IPanelGene[] => {
    const panelItemGeneList = isPrimary
      ? (primaryPanelData.geneList ?? [])
      : (optionalTestsData?.find(item => item.panelCode === panelCode)?.geneList ?? ([] as IPanelGene[]))
    return panelItemGeneList
  }

  const onGeneSelect = React.useCallback(
    (geneItem: IPanelGene, panelCode: string, isPrimary?: boolean, isRequired?: boolean) => {
      const isUnselected = unselectedGenes.some(item => item.name === geneItem.name)

      if (
        (isPrimary || isRequired) &&
        onlyOneGeneSelectedRemained(unselectedGenes, getCorrectSourceGeneList(panelCode, isPrimary)) &&
        !isUnselected
      ) {
        // will not change amount of unselected genes for primary panel if only one selected
        return
      } else if (isUnselected) {
        setUnselectedGenes(prev => prev.filter(item => item.name !== geneItem.name))
        setShowAdded(false)
      } else {
        const genes = [...unselectedGenes, geneItem]
        setUnselectedGenes(genes)
        setShowAdded(false)
        // will unselect whole add-on if all genes of this add-on are unselected
        if (!isPrimary) unselectPanelWhenAllGenesUnselected(genes, panelCode)
      }
    },
    [unselectedGenes, handleSelectedPanel, optionalTestsData, primaryPanelData],
  )

  return {
    cleanPanelCode,

    handleAddButtonClick,
    //handle select and add to cart
    handleSelectedPanel,
    isAddonProvided,
    //state utils
    isGeneCountEnabled,
    isNIPSMicrodeletionSyndromesAddon,
    onGeneSelect,
    optionalTestsData,
    primaryPanelData,
    resetSelectedItems,

    sectionHeading,
    //main data
    selectedPanels,
    setIsHoverOverTags,
    showAdded,
    showNameOnOrderTest,
    totalGenesCount,
    unselectedGenes,
  }
}

export default useHandlePanelCards
