import {IFeatureFlags} from '@invitae/physician-cart/dist/context'
import {FeatureFlagStateDto} from '@invitae/stargate'

import {
  CX_CANCER_PAYOR_ISSUES_ENABLED,
  CX_CUSTOM_RNA_HCX,
  CX_IS_STARGATE_CART_SVC_PROXY_ENABLED,
  CX_LOCK_PREVENTIVE_PANEL_CUSTOMIZATION,
  CX_ORDERING_CARRIER_NIPS_EXCEPTION,
  CX_PEDIATRIC_CMA_IN_SF,
  CX_REDIRECT_PROACTIVE_TO_GENETIC_RISK,
  CX_RETAIN_CUSTOM_PANEL_CONTEXT,
  IS_CANCER_PAYOR_ISSUES_ENABLED,
} from 'constants/featureFlags'

const defaultFeatureFlags: Record<string, boolean> = {
  [CX_CUSTOM_RNA_HCX]: false,
  [CX_IS_STARGATE_CART_SVC_PROXY_ENABLED]: false,
  [CX_LOCK_PREVENTIVE_PANEL_CUSTOMIZATION]: false,
  [CX_ORDERING_CARRIER_NIPS_EXCEPTION]: false,
  [CX_PEDIATRIC_CMA_IN_SF]: false,
  [CX_REDIRECT_PROACTIVE_TO_GENETIC_RISK]: false,
  [CX_RETAIN_CUSTOM_PANEL_CONTEXT]: false,
  [IS_CANCER_PAYOR_ISSUES_ENABLED]: false,
}

export const transformFeatureFlags = (featureFlags: FeatureFlagStateDto[] | null): IFeatureFlags => {
  const flags = defaultFeatureFlags
  featureFlags?.forEach(flag => {
    if (flag.name === CX_CANCER_PAYOR_ISSUES_ENABLED) {
      flags[IS_CANCER_PAYOR_ISSUES_ENABLED] = flag.enabled
    } else {
      flags[flag.name as keyof IFeatureFlags] = flag.enabled
    }
  })

  return flags as unknown as IFeatureFlags
}
