import parse from 'html-react-parser'
import {camelCase, kebabCase} from 'lodash'
import textile from 'textile-js'

import {CountryCode} from '@invitae/nucleobase'
import {CategoryV2PanelDto, GeneV2Dto, OrderConfigResDto, OrderConfigResVisibilityEnum} from '@invitae/stargate'

import {SponsoredTestingType, TestSelection} from 'api/types'
import {IGenesTabItem} from 'components/CategoryTabSection/GenesTab/GenesTabItem'
import {TDetailsPageType} from 'constants/pageType'
import {EXTERNAL_LINK_CLASS, HBA1_HBA2_GENE, REQUIRED_PRODUCT_TYPES, SMN1_SMN2_GENE} from 'constants/specialCases'
import {SYNTHETIC_TEST_USERS} from 'constants/syntheticTestUsers'

interface GetPortalTestUrlFromPrCodeProps {
  prCode: string
  catCode?: string
  countryCode?: CountryCode
}

export const getPortalTestUrlFromPrCode = ({
  prCode,
  catCode,
  countryCode = 'US',
}: GetPortalTestUrlFromPrCodeProps): string => {
  const query = catCode ? `?cat=${catCode}` : ''
  const clearPrCode = getCleanCode(prCode)

  if (!clearPrCode.length) {
    return ''
  }

  return `${
    process.env.INVITAE_BASE_URL
  }/${countryCode.toLowerCase()}/providers/test-catalog/test-${clearPrCode}${query}`
}

interface GetGeneUrlFromPrCodeProps {
  prCode: string
  countryCode?: CountryCode
}

export const getGeneUrlFromPrCode = ({prCode, countryCode = 'US'}: GetGeneUrlFromPrCodeProps): string => {
  const clearPrCode = getCleanCode(prCode)

  if (!clearPrCode.length) {
    return ''
  }

  return `${process.env.INVITAE_BASE_URL}/${countryCode.toLowerCase()}/providers/test-catalog/gene-${clearPrCode}`
}

/**
 *
 * @param code - raw panel code: "PR00001.11" or "PR00001"
 * @returns "00001"
 *
 * 1) removed all letters from the code
 * 2) removed ".11" from the end or code and returns
 */
export const getCleanCode = (code: string) => {
  const prCode = code.indexOf('.') >= 0 ? code.split('.')[0] : code
  return prCode.replace(/^\D+/g, '')
}

export const sortFullPanelListByListFromContentful = (
  panelListToSort: CategoryV2PanelDto[],
  subCategoryPanels: string[],
) => {
  const cleanSubCategoryPanels = subCategoryPanels.map(item => getCleanCode(item))
  return panelListToSort.sort(function (a, b) {
    const firstPanelCode = getCleanCode(a.code!)
    const secondPanelCode = getCleanCode(b.code!)
    return cleanSubCategoryPanels.indexOf(firstPanelCode) - cleanSubCategoryPanels.indexOf(secondPanelCode)
  })
}
/**
 * This method was "borrowed" from https://stackoverflow.com/a/28339742
 */
export const humanize = (str: string): string =>
  str
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_-\s]+/g, ' ')
    .replace(/^[a-z]/, m => m.toUpperCase())

export const IS_BROWSER = typeof window !== 'undefined'

export const scrollTo = (offsetY = 0, behavior?: 'smooth') => {
  if (!IS_BROWSER) return

  window.scrollTo({
    behavior: behavior || 'auto',
    top: offsetY,
  })
}

/**
 *
 * @param gene list to sort.
 * @returns - returns sorted list by NAME ignoring alias, etc.
 * changes the passed list as well
 */
export const sortGenesAlphabetically = (genes: IGenesTabItem[]) =>
  genes.sort((a, b) => a.name!.toLowerCase().localeCompare(b.name!.toLowerCase()))

/**
 *
 * @param gene list to sort.
 * @param query to sort by
 * @returns sorted genes by NAME that is most similar to the query
 */

export const sortGeneNamesByQuery = (genes: IGenesTabItem[], query: string) =>
  genes.sort((a, b) => {
    if (a.name!.toLowerCase().includes(query.toLowerCase()) && !b.name!.toLowerCase().includes(query.toLowerCase())) {
      //pushes to the top of the list
      return -1
    } else return 1
  })

export const isProdOrStg = () => process.env.INVITAE_ENV === 'prd' || process.env.INVITAE_ENV === 'stg'

export const isSyntheticTestUser = (userEmail: string) => SYNTHETIC_TEST_USERS.includes(userEmail.toLowerCase())

export const formatObjectPropertiesToCamelCase = (obj: {[key: string]: boolean}) => {
  const newObj: {[key: string]: boolean} = {}
  for (const property in obj) {
    newObj[camelCase(property)] = obj[property]
  }

  return newObj
}

export const parseHtml = (htmlString: string) => {
  if (htmlString && htmlString.length) {
    return parse(htmlString)
  }

  return null
}

export const parseHtmlTextile = (htmlString: string) => {
  if (htmlString && htmlString.length) {
    let textileParsed = textile.parse(htmlString)
    while (textileParsed.match(`${EXTERNAL_LINK_CLASS} href`)) {
      const index = textileParsed.indexOf(`${EXTERNAL_LINK_CLASS} href`) + EXTERNAL_LINK_CLASS.length
      textileParsed = `${textileParsed.slice(0, index)} target="_blank" ${textileParsed.slice(index + 1)}`
    }
    return parseHtml(textileParsed)
  }

  return null
}

export const checkIsLocked = (productType: string, featureFlags: Record<string, boolean> | undefined) => {
  if (featureFlags && featureFlags.cx_lock_preventive_panel_customization) {
    return REQUIRED_PRODUCT_TYPES.filter(item => item === 'PRODUCT_TYPE_PREVENTIVE').includes(productType)
  }
  return REQUIRED_PRODUCT_TYPES.includes(productType)
}

export const checkIfGeneNameSpecialCase = (name: string) => [HBA1_HBA2_GENE, SMN1_SMN2_GENE].includes(name)

export const filterPartnerships = (partnerships: OrderConfigResDto[]) => {
  const sponsoredTestingData: SponsoredTestingType = {}
  const filteredPartnerships = partnerships.filter(
    partnership =>
      partnership.isActive &&
      partnership.visibility === OrderConfigResVisibilityEnum.Everyone &&
      partnership.landingPageUrl &&
      partnership.landingPageUrl.length > 0,
  )
  filteredPartnerships.forEach(partnership => {
    const testSelectionGroupTypes = (partnership.testSelections as TestSelection[]).filter(
      // we care about 'GROUP' tests only
      // (https://github.com/invitae-internal/order-config-service/blob/07594aa7256ca35897d4c74ad91530250b1a179c/src/main/kotlin/com/invitae/orderconfigservice/models/TestNameType.kt)
      (code): code is TestSelection => code.testNameType === 'GROUP',
    )
    testSelectionGroupTypes.forEach(testSelection => {
      const testName = testSelection.testName.split('.')[0]
      if (sponsoredTestingData[testName])
        sponsoredTestingData[testName].push({
          displayName: partnership.displayName,
          endDate: partnership.endDate ?? '',
          id: partnership.id,
          landingPageUrl: partnership.landingPageUrl ?? '',
        })
      else {
        sponsoredTestingData[testName] = [
          {
            displayName: partnership.displayName,
            endDate: partnership.endDate ?? '',
            id: partnership.id,
            landingPageUrl: partnership.landingPageUrl ?? '',
          },
        ]
      }
    })
  })

  return sponsoredTestingData
}
export const getAnalyticsCurrentPageSection = (section: string, pageType: TDetailsPageType) =>
  `${section} section of ${pageType} page`

export const getIsSponsoredProgramDateDue = (endDate: string) => {
  const parsedEndDate = new Date(endDate)
  const getTenDaysBeforeDate = new Date(parsedEndDate.setDate(parsedEndDate.getDate() - 10))
  const isProgramDue = new Date() <= getTenDaysBeforeDate
  return isProgramDue
}

export const scrollToTopSmooth = () => {
  window.scrollTo({
    behavior: 'smooth',
    top: 0,
  })
}

export const getGeneByType = (genes: GeneV2Dto[], geneType: string) =>
  genes.find((item: GeneV2Dto) => kebabCase(item.productType) === kebabCase(geneType))

/**
 * Function to remove basePath ('/{locale}/providers/test-catalog') and returns just the code (gene, test, ...)
 * @param url {string}
 * @returns {string}
 */
export const removeBasePath = (url: string) => {
  const path = new RegExp('(.*/?)[a-zA-Z]{2}/providers/test-catalog/')

  return url.replace(path, '')
}
